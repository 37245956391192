import { Link } from 'gatsby';
import React, { useContext, useState } from 'react';

import axios from 'axios';

import { useTranslation } from 'react-i18next';
import UserContext from '../../contexts/UserContext';
import NavBar2 from '../../components/pricing/NavBar2';
import BlueTicket from '../../../static/images/blue-tick.svg';
import Secure from '../../../static/images/secure.svg';
// import Protected from '../../../static/images/protected.svg';
// import PayButton from '../../../static/images/paypal-button.svg'
import Fwd from '../../../static/images/doublefwd-arrow.svg';

export default function step2() {
  const { user, paymentDetails } = useContext(UserContext);
  //   const handleOpenPrintDialog = () => {
  //     if (typeof window !== `undefined`) {
  //       window && window.print();
  //     }
  //   };
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSubscriptions = async () => {
    if (paymentDetails.priceId) {
      setLoading(true);
      let userId = '';
      const usersInfo = localStorage.getItem('user');
      if (usersInfo) {
        const { uid } = JSON.parse(usersInfo);
        userId = uid;
      }
      const config = {
        method: 'POST',
        url: '/api/create-checkout-session',
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': 'https://www.simplecv.me',
          Accept: 'application/json',
        },
        data: JSON.stringify({
          priceId: paymentDetails.priceId,
          userId,
        }),
      };

      axios(config)
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);

            const { url } = res.data.data;
            window.location.href = `${url}`;
          }
        })
        .catch((err) => {
          setLoading(false);

          console.log(err);
        });
    }
  };

  return paymentDetails ? (
    user && paymentDetails.priceId ? (
      <>
        <NavBar2 />
        <div className="w-full bg-[#F2F5FA] h-[88vh]">
          <div className="container flex-wrap-reverse mx-auto flex justify-center space-x-0 md:space-x-12 py-28">
            <div className="w-full md:w-4/12 flex flex-col justify-center px-2 md:px-0 space-y-10">
              <h3 className="text-[#262B33] text-[28px] font-semibold">
                {t('pricing.step2.title')}
              </h3>
              <div className="flex flex-col space-y-3">
                <div className="flex space-x-4">
                  <img src={BlueTicket} alt="blue tick" />
                  <p className="text-[#262B33] text-[14px] font-normal">
                    {t('pricing.step2.payFeatures.0')}
                  </p>
                </div>
                <div className="flex space-x-4">
                  <img src={BlueTicket} alt="blue tick" />
                  <p className="text-[#262B33] text-[14px] font-normal">
                    {t('pricing.step2.payFeatures.1')}
                  </p>
                </div>
              </div>
              <div className="flex flex-col space-y-3">
                <h3 className="text-[#262B33] text-[18px] font-medium">
                  {t('pricing.step2.cancel.title')}
                </h3>
                <p className="text-[#6A7683] text-[13px] font-medium">
                  {t('pricing.step2.cancel.description')}
                </p>
              </div>

              <h3 className="text-[#262B33] text-[18px] font-medium ">
                {t('pricing.step2.protected')}
              </h3>

              <div className="flex justify-start space-x-8">
                {/* <img src={Protected} alt="protected" /> */}
                <img src={Secure} alt="secure" />
              </div>
            </div>
            <div className="w-full md:w-4/12 flex p-6  bg-white rounded-3xl space-y-10 flex-col justify-center">
              <div className="flex justify-between w-full">
                <h1 className="text-[#262B33] text-[24px] font-medium">
                  {paymentDetails ? paymentDetails.priceType : ''} Plan
                </h1>
                <p className="text-[#3346D3] text-[24px] font-semibold">
                  ${paymentDetails ? paymentDetails.priceAmount : ''}
                </p>
              </div>
              <button
                className="bg-[#3346D3] text-white text-[21px] items-center font-semibold flex space-x-3 justify-center py-5 rounded-2xl w-full "
                onClick={() => {
                  handleSubscriptions();
                }}
              >
                {loading ? (
                  <>
                    <svg
                      className="animate-spin -inline-block w-8 h-8 border-4 rounded-full"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx={12}
                        cy={12}
                        r={10}
                        stroke="currentColor"
                        strokewidth={4}
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    <span>{t('shared.buttons.processing')}</span>
                  </>
                ) : (
                  <>
                    <span>{t('shared.buttons.payByCard')}</span>{' '}
                    <img src={Fwd} alt="fwd" />
                  </>
                )}
              </button>
              {/* <button className='border-[1px] border-[#199DDB] border-solid text-white text-[21px] font-semibold flex justify-center py-5 rounded-2xl w-full '>
                                <img src={PayButton} alt="paypal" />
                            </button> */}

              <div className="p-6 border-[1px] space-y-4 border-solid border-[#707085] rounded-2xl text-center">
                <p className="text-[14px] font-medium">
                  {t('pricing.step2.card.description')}
                </p>
                <p className="text-[14px] text-[#707085] font-medium">
                  {t('pricing.step2.card.subDescription')}
                </p>
                <div className="flex space-x-2 justify-center divide-x">
                  <Link
                    className="text-[#707085] underline"
                    to="/terms-of-service"
                  >
                    {t('pricing.step2.card.links.terms')}
                  </Link>
                  <Link
                    className="text-[#707085] underline pl-[6px]"
                    to="/privacy-policy"
                  >
                    {t('pricing.step2.card.links.privacy')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : (
      <h1>Forbidden</h1>
    )
  ) : (
    <h1>Forbidden</h1>
  );
}
