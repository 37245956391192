import { Link } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../../static/images/logo-main.svg';
import WhiteTicket from '../../../static/images/tick-white.svg';

export default function NavBar2() {
  const { t } = useTranslation();
  return (
    <div className="flex justify-around flex-col md:flex-row container py-6">
      <div className="flex justify-center md:justify-start space-x-2 items-center w-full md:w-3/12 ml-0 md:ml-6">
        <img src={logo} alt="logo" />
        <Link
          to="/"
          className="font-semibold  text-[18px] text-[#363C4B] hover:no-underline"
        >
          SimpleCV
        </Link>
      </div>
      <div className="w-full md:flex-nowrap flex-wrap md:w-7/12 pr-0 md:pr-6 pt-6 md:pt-0 md:flex-row flex justify-center items-center space-x-0 md:space-x-1 space-y-8 md:space-y-0">
        <h3 className="text-[#262B33] justify-center flex items-center text-center w-5/12 md:w-3/12 text-[13px] font-meduim mt-6 md:mt-0">
          {' '}
          <img
            className="bg-[#3D41F1] text-white w-8 rounded-full py-2 px-2 mr-1"
            src={WhiteTicket}
            alt="whitetick"
          />{' '}
          {t('pricing.header.0')}
        </h3>
        <div className="flex-grow w-2/12 md:w-[40px] border-t border-[#DDE3EF]" />
        <h3 className="text-[#262B33] justify-center flex items-center text-center w-5/12 md:w-3/12 text-[13px] font-meduim ">
          {' '}
          <img
            className="bg-[#3D41F1] text-white w-8 rounded-full py-2 px-2 mr-1"
            src={WhiteTicket}
            alt="whitetick"
          />{' '}
          {t('pricing.header.1')}
        </h3>
        <div className="flex-grow hidden md:block md:w-[40px] border-t border-[#DDE3EF]" />
        <h3 className="text-[#262B33] w-5/12 md:w-4/12 text-center text-[13px] font-meduim">
          {' '}
          <span className="bg-[#DDE3EF] text-white text-[14px] rounded-full py-1 px-3 mr-1">
            3
          </span>{' '}
          {t('pricing.header.2')}
        </h3>
        <div className="flex-grow w-2/12 md:w-[40px]  border-t border-[#DDE3EF]" />
        <h3 className="text-[#9799B1] w-5/12 md:w-3/12 text-center text-[13px] font-meduim">
          {' '}
          <span className="bg-[#DDE3EF] text-white text-[14px] rounded-full py-1 px-3 mr-1">
            4
          </span>{' '}
          {t('pricing.header.3')}
        </h3>
      </div>
      <div className="w-full md:w-2/12 hidden md:flex mt-8 md:mt-0 justify-center md:justify-end pr-4 items-center space-x-8 md:space-x-4 mr-0 md:mr-2">
        <Link to="/">X</Link>
      </div>
    </div>
  );
}
